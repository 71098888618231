<template>
	<div class="settings-content">
		<div class="row-space-tbf">
			<div class="space-left"></div>
			<div class="content software-updates-page" v-if="loaded">
				<template v-if="updateData">
					<div class="header-update">
						<div class="title">{{ updateData.name }}</div>
						<div class="crud-actions" v-if="$resize && $mq.below(820)">
							<button class="btn-tbf white only-icon open-sidebar" @click="showModal('list_updates')" >
								<icon-settings />
							</button>
						</div>
					</div>
					<div class="content-update" v-html="updateData.description" v-if="updateData"></div>
				</template>
				<div class="message-empty-update" v-else>{{ $t('software_updates.empty_title') }}</div>
			</div>
			<div class="content" v-else>
				<div class="content-update placeholder-loader" style="height: 200px; width: 100%;"></div>
				<div class="placeholder-loader" style="height: 300px; width: 100%; margin-top: 15px;"></div>
				<div class="placeholder-loader" style="height: 100px; width: 100%; margin-top: 15px;"></div>
			</div>
			<div class="space-right"></div>
		</div>
	</div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow.vue'
	import IconSettings from '../../Icons/Settings'

	export default {
		components: {
			IconArrow,
			IconSettings
		},
		data() {
			return {
				loaded: false,
				updateData: '',
				softwareUpdates: [],
				typeUrlShow: ''
			};
		},
		watch:{
			$route (to, from){
				if(to.params.id) {
					this.getSoftwareUpdate()
					var title =  this.$t('software_updates.title_work_in_progress');
					this.$root.$emit("navbar_title", title);
				}
			}
		},
		async mounted(){
			var title =  this.$t('software_updates.title_work_in_progress');
			this.$root.$emit("navbar_title", title);
			
			if(this.$route.params && this.$route.params.id){
				this.getSoftwareUpdate()
			}

			this.$root.$on('showEmptyUpdates', () => {
				var title =  this.$t('software_updates.title_work_in_progress');
				this.$root.$emit("navbar_title", title);

				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			})

			if(['work-in-progress'].includes(this.$route.name)) {
				await this.getSoftwareUpdates();
			}
		},
		methods: {
			getSoftwareUpdate(){
				axios.get(`/software-updates/${this.$route.params.id}/show`)
				.then(({data}) => {
					this.updateData = data.data
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
						if(!this.updateData.seen){ this.markAsSeen() }
					}, 0)
				})
			},
			markAsSeen(){
				axios.post(`/software-updates/${this.updateData.id}/mark-as-seen`)
				.then(() => {
					this.$auth.fetch()
				})
			},
			showModal(type, data = false, itemId = false){
				this.$root.$emit('open_modal', type, data, itemId);
			},
			async getSoftwareUpdates() {
				var typeList = 'work_in_progress'
				this.typeUrlShow = 'work-in-progress-show'

				await axios.get('/software-updates', { params: { type: typeList }})
				.then(({data}) => {
					let listData = data.data;

					if(this.$route.name == 'work-in-progress') {
						if(listData.length) {
							if(listData.find(el => !el.seen)) {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData.find(el => !el.seen).id }})
							} else {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData[0].id }})
							}
						} else {
							var title =  this.$t('software_updates.title_work_in_progress');
							this.$root.$emit("navbar_title", title);

							this.loaded = true
							setTimeout(() => {
								$('.opacity-page').addClass('show')
							}, 0)
						}
					}
				})
			},
		}
	};
</script>

<style lang="scss">
	.content-update{
		img{
			max-width: 100%;
			height: auto;
		}
	}
</style>